import { Injectable } from '@angular/core';
// const FileSaver = require('file-saver');
import { saveAs } from 'file-saver-es';
import * as XLSX from 'xlsx';
import { EXCEL_EXTENSION, EXCEL_TYPE } from '../constant';

@Injectable({
  providedIn: 'root'
})
export class ExcelService {
  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }
  public exportDataExcelFile(json: any[], excelFileName: string, option: any): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  public saveAsExcelFile(buffer: any, fileName: string, isMasterReport?: boolean): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    if (isMasterReport) saveAs(data, fileName + EXCEL_EXTENSION);
    else saveAs( data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION );
  }

  public toExcel(
    data: any[],
    sequence: string[],
    headers: string[][],
    filename: string,
    merges?: string[]
  ): void {
    const worksheet = XLSX.utils.json_to_sheet(data, { header: sequence });
    const workbook = XLSX.utils.book_new();

    //const ws = XLSX.utils.aoa_to_sheet(headers);
    if (merges?.length) {
      const mergedCells = merges?.map((el) => XLSX.utils.decode_range(el));
      worksheet['!merges'] = mergedCells;
    }

    XLSX.utils.sheet_add_aoa(worksheet, headers, { origin: 'A1' });
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');

    XLSX.writeFile(workbook, `${filename}.xlsx`, { compression: true });
  }
}
