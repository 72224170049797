import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BusinessEntityService {

  businessEntityData = new BehaviorSubject<any>({ entityName: '', isTaxPayer: false });
  pobDataSubject$ = new BehaviorSubject<any>({ pobValue: '' });
  legalDataSubject$ = new BehaviorSubject<any>({});

  constructor(
    private http: HttpClient
  ) { }

  setPobVal(val: any) {
    this.pobDataSubject$.next({
      data: val
    });
  }
  setLegalHierarchy(val: any) {
    this.legalDataSubject$.next({
      data: val
    });
  }

  getPobVal(): Observable<any> {
    return this.pobDataSubject$.asObservable();
  }
  getLegalVal(): Observable<any> {
    return this.legalDataSubject$.asObservable();
  }

  fetchEntity() {
    return this.http.get(`${environment.apiUrl}/mgmt/user/entity`).pipe(map((response) => {
      return response;
    }));
  }
  getLegalRegNum(data:any) {
    return this.http.get(`${environment.apiUrl}/mgmt/user/company/getAssignedGstins?companyId=${data.companyId}&userMailId=${data.userMailId}`).pipe(map((response) => {
      return response;
    }));
  }

  fetchFillingBusiness() {
    return this.http.get(`${environment.apiUrl}/mgmt/user/company/filingbusiness`).pipe(map((response) => {
      return response;
    }));
  }

  fetchBusinessHierarchy(companyId:any) {
    return this.http.get(`${environment.apiUrl}/mgmt/company/businesshierarchy?companyid=${companyId}`).pipe(map((response) => {
      return response;
    }));
  }

  changeCompany(companyId:any) {
    return this.http.post(`${environment.apiUrl}/mgmt/user/changecompany?companyid=${companyId}`, {}).pipe(map((response) => {
      return response;
    }));
  }

  validateGstnSessionUrl(gstn:any) {
    return this.http.get(`${environment.apiUrl}/mgmt/company/nicCredentialsStatus?gstin=${gstn}`).pipe(map((response) => {
      return response;
    }));
  }

  // setBusinessData(data) {
  //   this.businessEntityData.next(data);
  // }
  // getPageData() {
  //   return this.businessEntityData.asObservable();
  // }
  //getSetBusinessData
  // setBusinessData(data?:any) {
  //   if (data) {
  //     this.businessEntityData.next(data);

  //   }
  // }
  // getBusinessData() {
  //     return this.businessEntityData.asObservable();

  // }

}
