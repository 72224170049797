import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { NoticeVcDateColumn, ProAdjDateColumn, STATE_LIST } from '../constant';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  stateList: any = [];


  constructor(
    private http: HttpClient,
    private _router: Router
  ) {
    this.stateList = STATE_LIST;
  }

  /* Dashboard news and update  */
  newsUpdate() {
    return this.http.get(`${environment.apiUrl}/mgmt/news`).pipe(map((resp) => {
      return resp;
    }));
  }
  getGstAllDueDate(data: any) {
    return this.http.post(`${environment.baseUrl}/gst/query/duedates`, data);
  }
  getPreGstAllDueDate(data: any) {
    return this.http.post(`${environment.baseUrl}/pregst/query/duedates`, data);
  }

  getGstHearingDates(data: any) {
    return this.http.post(`${environment.baseUrl}/gst/query/hdates`, data);
  }
  getPreGstHearingDates(data: any) {
    return this.http.post(`${environment.baseUrl}/pregst/query/hdates`, data);
  }
  auditDate(data: any) {
    return this.http.post(`${environment.baseUrl}/audit/duedates`, data);
  }

  getStateName(code: any) {
    if (code)
      return this.stateList.find((x: { stateCode: any; }) => x.stateCode == code).stateName;
  }

  getNavContext() {
    return JSON.parse(sessionStorage.getItem('navContext') || '{}');
  }
  userRole(data: any) {
    let companyId = +(localStorage.getItem('selectedId') || '{}');
    return this.http.get(`${environment.apiUrl}/mgmt/user/userRoleInfo?companyId=${companyId}&userMailId=${data.userMailId}`).pipe(map((resp) => {

      // return this.http.get(`${environment.apiUrl}/mgmt/user/userRoleInfo?companyId=${companyId}`).pipe(map((resp) => {
      return resp;
    }));
  }

  reloadCurrentRoute() {
    let currentUrl = this._router.url;
    this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this._router.navigate([currentUrl]);
    });
  }
  getRandomColor() {
    var r = Math.floor(Math.random() * 155);
    var g = Math.floor(Math.random() * 255);
    var b = Math.floor(Math.random() * 455);
    return "rgb(" + r + "," + g + "," + b + ")";
  }
  sortValue(value: number) {
    if (value) {
      return (value).toFixed(2);
    }
    return 0;
  }

  sortObjectsByKey(objArray: any[], sortKey: string, sortType?: 'asc' | 'desc') {
    if (!sortType || sortType === 'asc') {
      return objArray.sort((x: any, y: any) => {
        if (x[sortKey] < y[sortKey]) {
          return -1;
        } else if (x[sortKey] > y[sortKey]) {
          return 1;
        } else {
          return 0;
        }
      });
    } else {
      return objArray.sort((x: any, y: any) => {
        if (x[sortKey] > y[sortKey]) {
          return -1;
        } else if (x[sortKey] < y[sortKey]) {
          return 1;
        } else {
          return 0;
        }
      });
    }
  }

  sortProcAdj(objArray: any[], insideKeyName: string, sortKey: string, sortType?: 'asc' | 'desc') {
    if (ProAdjDateColumn.hasOwnProperty(sortKey)) {
      if (sortType === 'asc') {
        return objArray.sort((a: any, b: any) => {
          const dateA: Date = new Date(a[insideKeyName][ProAdjDateColumn[sortKey]]);
          const dateB: Date = new Date(b[insideKeyName][ProAdjDateColumn[sortKey]]);
          return dateB.getTime() - dateA.getTime();
        });
      } else {
        return objArray.sort((a: any, b: any) => {
          const dateA: Date = new Date(a[insideKeyName][ProAdjDateColumn[sortKey]]);
          const dateB: Date = new Date(b[insideKeyName][ProAdjDateColumn[sortKey]]);
          return dateA.getTime() - dateB.getTime();
        });
      }
    } else {
      if (!sortType || sortType === 'asc') {
        return objArray.sort((x: any, y: any) => {
          if (x[insideKeyName][sortKey] < y[insideKeyName][sortKey]) {
            return -1;
          } else if (x[insideKeyName][sortKey] > y[insideKeyName][sortKey]) {
            return 1;
          } else {
            return 0;
          }
        });
      } else {
        return objArray.sort((x: any, y: any) => {
          if (x[insideKeyName][sortKey] > y[insideKeyName][sortKey]) {
            return -1;
          } else if (x[insideKeyName][sortKey] < y[insideKeyName][sortKey]) {
            return 1;
          } else {
            return 0;
          }
        });
      }
    }

  }
  sortNoticeVc(objArray: any[], insideKeyName: string, innerInsideKeyName: string, sortKey: string, sortType?: 'asc' | 'desc') {
    if (NoticeVcDateColumn.hasOwnProperty(sortKey)) {
      if (sortType === 'asc') {
        return objArray.sort((a: any, b: any) => {
          const dateA: Date = new Date(a[insideKeyName][innerInsideKeyName][NoticeVcDateColumn[sortKey]]);
          const dateB: Date = new Date(b[insideKeyName][innerInsideKeyName][NoticeVcDateColumn[sortKey]]);
          return dateB.getTime() - dateA.getTime();
        });
      } else {
        return objArray.sort((a: any, b: any) => {
          const dateA: Date = new Date(a[insideKeyName][innerInsideKeyName][NoticeVcDateColumn[sortKey]]);
          const dateB: Date = new Date(b[insideKeyName][innerInsideKeyName][NoticeVcDateColumn[sortKey]]);
          return dateA.getTime() - dateB.getTime();
        });
      }
    } else {
      if (!sortType || sortType === 'asc') {
        return objArray.sort((x: any, y: any) => {
          if (x[insideKeyName][innerInsideKeyName][sortKey] < y[insideKeyName][innerInsideKeyName][sortKey]) {
            return -1;
          } else if (x[insideKeyName][innerInsideKeyName][sortKey] > y[insideKeyName][innerInsideKeyName][sortKey]) {
            return 1;
          } else {
            return 0;
          }
        });
      } else {
        return objArray.sort((x: any, y: any) => {
          if (x[insideKeyName][innerInsideKeyName][sortKey] > y[insideKeyName][innerInsideKeyName][sortKey]) {
            return -1;
          } else if (x[insideKeyName][innerInsideKeyName][sortKey] < y[insideKeyName][innerInsideKeyName][sortKey]) {
            return 1;
          } else {
            return 0;
          }
        });
      }
    }
  }

  sortObjectsByDate(objArray: any[], sortKey: string, sortType: 'asc' | 'desc') {
    if (sortType === 'asc') {
      objArray.sort((a: any, b: any) => {
        const dateA: Date = new Date(a[sortKey]);
        const dateB: Date = new Date(b[sortKey]);
        return dateB.getTime() - dateA.getTime();
      });
    } else {
      objArray.sort((a: any, b: any) => {
        const dateA: Date = new Date(a[sortKey]);
        const dateB: Date = new Date(b[sortKey]);
        return dateA.getTime() - dateB.getTime();
      });
    }
  }
  sortLitigationDataSort(objArray: any[], sortKey: string, sortType: 'desc' | 'asc'): any[] {
    return objArray.sort((a: any, b: any) => {
      const dateA: Date = new Date(this.formatDateString(a[sortKey]));
      const dateB: Date = new Date(this.formatDateString(b[sortKey]));

      if (sortType === 'asc') {
        return dateA.getTime() - dateB.getTime();
      } else {
        return dateB.getTime() - dateA.getTime();
      }
    });
  }

  private formatDateString(dateString: string): string {
    // Assuming dateString is in the format 'DD-MM-YYYY'
    const parts = dateString.split('-');
    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10) - 1; // Month is zero-based
    const year = parseInt(parts[2], 10);
    return new Date(year, month, day).toISOString();
  }

  getCurrentDate() {
    const currentDate = new Date();
    const day = String(currentDate.getDate()).padStart(2, '0');
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const year = currentDate.getFullYear();
    const formattedDate = `${day}-${month}-${year}`;
    return formattedDate;
  }

  getAmmountDt(data: any) {
    return this.http.post(`${environment.apiUrl}/lms2/dt/query/amounts`, data);
  }
  getAllcountDt(data: any) {
    return this.http.post(`${environment.apiUrl}/lms2/dt/query/count/total`, data);
  }
  getDtHdates(data: any) {
    return this.http.post(`${environment.apiUrl}/lms2/dt/query/hdates`, data);
  }
  getDtDuedates(data: any) {
    return this.http.post(`${environment.apiUrl}/lms2/dt/query/duedates`, data);
  }
}
