import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class MasterService {
  userEmail: string;
  rootCompanyId: number = Number(sessionStorage.getItem('companyId'));

  constructor(private http: HttpClient) {
    this.userEmail = sessionStorage.getItem('UserId') ?? '';
  }

  getCompanyId() {
    const navContext = JSON.parse(sessionStorage.getItem('navContext') ?? '{}');
    return navContext?.companyId ?? this.rootCompanyId;
  }

  getPANList() {
    const payload = {
      uniqueCompanyId: String(this.getCompanyId()),
      userMailId: this.userEmail
    };

    return this.http
      .get(`${environment.apiUrl}/mgmt/user/company/assignedLegalPansByUniqueCmpId`, {
        params: payload
      })
      .pipe(map((resp: any) => resp?.response ?? []));
  }
}
